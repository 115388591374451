import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import heartMark from '../assets/images/heart-mark.svg'
import PageWidth from '../styles/PageWidth'

const AboutStyle = styled.div`
  padding: calc(var(--section-spacing) * 0.5) 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  align-items: center;
  gap: 3rem;
  .image-wrapper {
    padding: calc(var(--section-spacing));
    background: white url(${heartMark}) no-repeat;
    background-size: 110%;
    background-position-x: center;
  }
  .image {
    border-radius: 999px;
    box-shadow: var(--box-shadow);
  }
  @media (max-width: 440px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`

export default function AboutSection() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "carebeds-family.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <PageWidth>
      <AboutStyle id="what-we-do">
        <div>
          <h2>About Carebeds</h2>
          <p>
            Carebeds is a private sector enterprise, focused on the provision of
            high quality, affordable accommodation and wellbeing for our
            society’s most vulnerable. Carebeds is committed to a strengths-led
            approach to positive learning and therapeutic engagement. The
            Carebeds team has decades of experience working in our community and
            in accommodation provision. On a daily basis, our volunteers and
            associates work closely with essential service providers and care
            givers from NIHE, Family Support NI / 16 Plus, YoungMinds (CAMHS),
            Extern, Praxis and Foyle Haven.
          </p>
        </div>
        <div className="image-wrapper">
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Mother and daughter"
            className="image"
          />
        </div>
      </AboutStyle>
    </PageWidth>
  )
}
