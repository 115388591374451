import React from 'react'
import AboutSection from '../components/AboutSection'
import GetInvolvedSection from '../components/GetInvolvedSection'
import Header from '../components/Header'
import StoriesSection from '../components/StoriesSection'

export default function HomePage() {
  return (
    <>
      <Header />
      <AboutSection />
      <hr />
      <StoriesSection />
      <hr />
      <GetInvolvedSection />
    </>
  )
}
