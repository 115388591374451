import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import PageWidth from '../styles/PageWidth'
import cbMark from '../assets/images/cb-mark-trans.svg'
import StoryItem from './StoryItem'

const StoriesStyle = styled.div`
  padding: calc(var(--section-spacing)) 0;
  background: white url(${cbMark}) no-repeat;
  background-position: 140% 50%;
  background-size: 80%;
  h2 {
    margin-top: 0;
  }
  .stories-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    justify-content: center;
    align-items: start;
  }
`

export default function StoriesSection() {
  const data = useStaticQuery(graphql`
    query {
      grace: file(relativePath: { eq: "stories/grace.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stephen: file(relativePath: { eq: "stories/stephen.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ellie: file(relativePath: { eq: "stories/ellie.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      martin: file(relativePath: { eq: "stories/martin.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jenny: file(relativePath: { eq: "stories/jenny.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paul: file(relativePath: { eq: "stories/paul.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      derek: file(relativePath: { eq: "stories/derek.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StoriesStyle id="stories">
      <PageWidth>
        <h2>Some of our stories</h2>
        <div className="stories-wrapper">
          <StoryItem
            image={data.grace.childImageSharp.fluid}
            name="Grace & Children"
            description="Grace, a key healthcare worker, and her two young children found themselves homeless when their landlord’s bank repossessed their home just before the first lockdown in March. Grace desperately needed safe and secure accommodation until she could find another home. With the majority of our staff suddenly isolating or shielding, closing the business seemed inevitable; Grace and her young family inspired us to find a way to keep open and keep being an asset to our community. Cases like Grace’s proved to be the tip of the iceberg as lockdown took hold, and more and more people found themselves displaced, or disconnected from their families and jobs."
          />
          <StoryItem
            image={data.stephen.childImageSharp.fluid}
            name="Stephen"
            description="Stephen had hit rock bottom when he arrived with us. A move away from Derry to complete his higher education in Belfast had resulted in an unhealthy relationship with alcohol for this young man. Stephen was left with the stark choice of sleeping rough in Belfast, or sharing facilities where hard drug use was prevalent. Stephen knew that neither option would help him to find the stability that he needed to chart his pathway to recovery. Although relieved to finally be back in his hometown, Stephen needed professional support to manage his mental health. With the phenomenal support of other agencies (including the NHS and AA), Stephen started to find his feet and continues to make excellent progress in his journey to recovery. Always keen to help others and keep himself busy, Stephen loves volunteering for tasks around the property and grounds, and loves to run to the shops for other less mobile guests."
          />
          <StoryItem
            image={data.ellie.childImageSharp.fluid}
            name="Ellie"
            description="Ellie spent her childhood in care, having come from a broken home with parents who both struggled with addiction. Working in conjunction with YoungMinds / CAMHS, Ellie has stayed with us on a number of occasions for a few weeks at a time. She is a lovely, bubbly young woman, and during her stays we have been able to work with her to help her to manage her finances and meal times, and to develop daily routines. She has also been able to  spend time with other guests in a safe and secure environment. Ellie has regular daily meetings with counsellors onsite over tea and biscuits."
          />
          <StoryItem
            image={data.martin.childImageSharp.fluid}
            name="Martin"
            description="A man in his twenties from County Derry, Martin has experienced learning difficulties his whole life and was still living with his parents when lockdown began. Martin and his parents began to struggle with mental health issues, and Martin began to drink heavily. His elderly parents were unable to continue to provide care and a roof over his head and he found himself homeless. By the time Martin arrived at Carebeds he had developed an alcohol dependency. Our staff worked hard to fill the void left by alcohol, and over the course of a month Martin began to enjoy an alcohol-free life and has since been welcomed home by his parents."
          />
          <StoryItem
            image={data.jenny.childImageSharp.fluid}
            name="Jenny & Family"
            description="Jenny’s partner Gavin had to leave their home after his gambling addiction meant he could no longer continue to live with his family. He arrived at CareBeds distraught at having to leave his partner and young children behind. Extern accepted Gavin into their program where he gained valuable life skills and was able to make a fresh start. When a family room became available at Carebeds, Gavin’s partner and children were able to join him in a self-contained apartment, providing the young family with a sense of normality. Gavin, Jenny and their lovely children were a delight to have staying with us. Using the skills Gavin picked up at Extern, he was able to find a full time job as a chef and move to suitable longer-term accommodation closer to the rest of his family."
          />
          <StoryItem
            image={data.paul.childImageSharp.fluid}
            name="Paul"
            description="Paul’s life was in good shape, a steady job, good health, and engaged to his childhood sweetheart. Then lockdown began and Paul lost his job, his relationship with his fiancee ended due to her alcohol addiction, and he lost his home. Paul went from having a good job and a secure home to delivering pizzas at night for a local takeaway and sleeping in his car. His mental health began to decline. Paul was incredibly worried about the prospect of moving into a hostel; Paul didn't drink or use drugs and had heard rumours that both were rife in local hostels. His placement with Carebeds gave him the stability and peaceful environment where Paul could take stock of his situation and plan to get himself back on track. After two months Paul got a suitable apartment and a steady job with a local engineering firm. Paul continues to drop in and say hello from time to time and is always thankful for the time he spent at Carebeds."
          />
          <StoryItem
            image={data.derek.childImageSharp.fluid}
            name="Derek"
            description="Derek was gripped by alcoholism as a teenager and by young adulthood found himself homeless and destitute. A lovely young man, Derek was a model guest. Over the course of a few months Derek was supported by Extern and discovered a passion for cooking and gained a cookery qualification.  Derek soon found himself a job in a local restaurant kitchen, and went on to settle in an apartment with his new girlfriend."
          />
        </div>
      </PageWidth>
    </StoriesStyle>
  )
}
