import React from 'react'
import styled from 'styled-components'
import PageWidth from '../styles/PageWidth'
import cbMark from '../assets/images/cb-mark.svg'

const HeaderStyle = styled.div`
  background-color: var(--blue);
  background: var(--blue) url(${cbMark}) no-repeat;
  background-position: 140% 50%;
  background-size: 80%;
  padding: calc(var(--section-spacing) * 1.4) 0;
  h1 {
    color: white;
    margin-top: 0;
    margin-bottom: 3rem;
    width: 60%;
    line-height: 5rem;
    @media (max-width: 991px) {
      width: unset;
    }
  }
  @media (max-width: 991px) {
    background-position: 140px 120%;
    background-size: 100%;
  }
`

export default function Header() {
  return (
    <HeaderStyle>
      <PageWidth>
        <h1>
          Providing accommodation and wellbeing for society's most vulnerable.
        </h1>
        <a className="button pink" href="#get-involved">
          Get Involved
        </a>
      </PageWidth>
    </HeaderStyle>
  )
}
