import React, { useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StoryItemStyle = styled.div`
  text-align: center;
  border-bottom: 5px solid var(--pink);
  .image {
    border-radius: 999px;
    width: 40%;
    margin: 3rem auto;
  }
  .description-wrapper {
    position: relative;
  }
  .description {
    transition: all 0.5s ease;
    overflow: hidden;
    max-height: 8em;
    &.open {
      max-height: 2000px;
    }
  }
  .description-overlay {
    transition: all 0.8s ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 60%;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    &.open {
      top: 100%;
    }
  }

  .read-more {
    border: none;
    background: none;
    padding: 1.2rem;
    color: var(--blue);
    outline: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export default function StoryItem({ image, name, description }) {
  const [open, setOpen] = useState(false)
  return (
    <StoryItemStyle key={name} className="panel">
      <Img fluid={image} alt={name} className="image" />
      <h3>{name}</h3>
      <div className="description-wrapper">
        <p className={`description${open ? ' open' : ''}`}>{description}</p>
        <div className={`description-overlay${open ? ' open' : ''}`} />
      </div>
      <button
        type="button"
        className="read-more"
        onClick={() => setOpen(!open)}
        onKeyPress={() => setOpen(!open)}
      >
        {open ? 'Show less' : 'Read more'}
      </button>
    </StoryItemStyle>
  )
}
