import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PageWidth from '../styles/PageWidth'
import useForm from '../utilities/useForm'
import useContact from '../utilities/useContact'

const GetInvolvedStyle = styled.div`
  padding: calc(var(--section-spacing)) 0;
  h2,
  h3 {
    margin-top: 0;
  }
  h3 {
    margin-bottom: 3rem;
  }
  .info {
    margin-bottom: 3rem;
  }
  .get-involved-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: var(--gap-items);
    align-items: start;
  }
  .contact-form {
    grid-column: 1 / span 3;
  }
  form {
    display: grid;
    gap: 2rem;
  }
  .donate {
    grid-column: 4 / span 2;
  }
  .donate-info {
    display: grid;
    gap: 2rem;
    p {
      margin: 0;
    }
  }
  .org {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    align-items: center;
    gap: 3rem;
    a {
      justify-self: end;
    }
  }
  @media (max-width: 1000px) {
    .contact-form {
      grid-column: 1 / span 2;
    }
    .donate {
      grid-column: 3 / span 2;
    }
  }
  @media (max-width: 800px) {
    .contact-form {
      grid-column: 1 / span 1;
    }
    .donate {
      grid-column: 1 / span 1;
    }
  }
`

const FormStyle = styled.form`
  display: grid;
  gap: 2rem;
`

export default function GetInvolvedSection() {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "extern-logo.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "depaul-logo.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { values, updateValue } = useForm({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    honey: '',
  })
  const { error, loading, message, submitted, submitContactForm } = useContact({
    values,
  })
  return (
    <GetInvolvedStyle id="get-involved">
      <PageWidth>
        <h2>Get Involved</h2>
        <p className="info">
          We all need a little help sometimes. If you would like to volunteer,
          please complete our contact form to register your interest and
          indicate your area of experience or expertise.
        </p>
        <div className="get-involved-options">
          <div className="contact-form panel">
            <h3>Contact Us</h3>
            <FormStyle onSubmit={submitContactForm}>
              <div className="form-row">
                <label htmlFor="firstName">
                  First Name
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={values.firstName}
                    onChange={updateValue}
                    required
                    disabled={loading || submitted}
                  />
                </label>
                <label htmlFor="lastName">
                  Last Name
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={values.lastName}
                    onChange={updateValue}
                    required
                    disabled={loading || submitted}
                  />
                </label>
              </div>
              <div className="form-row">
                <label htmlFor="email">
                  Email
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={updateValue}
                    required
                    disabled={loading || submitted}
                  />
                </label>
                <label htmlFor="phone">
                  Phone (optional)
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={values.phone}
                    onChange={updateValue}
                    disabled={loading || submitted}
                  />
                </label>
              </div>
              <div className="form-row">
                <label htmlFor="message">
                  Message
                  <textarea
                    name="message"
                    id="message"
                    rows="5"
                    placeholder="How would you like to get involved?"
                    value={values.message}
                    onChange={updateValue}
                    required
                    disabled={loading || submitted}
                  />
                </label>
              </div>
              <input
                type="text"
                name="honey"
                id="honey"
                value={values.honey}
                onChange={updateValue}
                className="honey"
              />
              <div className="form-row">
                <button
                  type="submit"
                  disabled={loading || submitted}
                  className={submitted ? 'complete' : ''}
                >
                  <span aria-live="assertive" aria-atomic="true">
                    {loading ? 'Sending message...' : ''}
                  </span>
                  {loading ? '' : submitted ? 'Message Sent' : 'Send Message'}
                </button>
              </div>
              {error ||
                (message && (
                  <div className="text-center">{error || message || ''}</div>
                ))}
            </FormStyle>
          </div>
          <div className="donate panel">
            <h3>Donate</h3>
            <div className="donate-info">
              <p>
                If you would prefer to contribute financially, please consider
                the following organizations.
              </p>
              <hr />
              <div className="org">
                <Img
                  fluid={data.image1.childImageSharp.fluid}
                  alt="Extern logo"
                />
                <a
                  className="button pink text-center"
                  href="https://www.extern.org/"
                  title="Extern website"
                >
                  Donate Now
                </a>
              </div>
              <hr />
              <div className="org">
                <Img
                  fluid={data.image2.childImageSharp.fluid}
                  alt="Depaul logo"
                />
                <a
                  className="button pink text-center"
                  href="https://ie.depaulcharity.org/"
                  title="Depaul Charity website"
                >
                  Donate Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </PageWidth>
    </GetInvolvedStyle>
  )
}
